import styled from "styled-components";

export const StyledHeader = styled.div`
         background-repeat: no-repeat;
         background-size: cover;
         color: ${(props) => props.theme.white};
         text-align: center;
         background-position: center;
         position: relative;
       `;

export const StyledLogo = styled.img`
  max-width: 220px;
  width:100%;
  margin-top: 20px;
`;
