import styled from "styled-components";

export const StyledHeaderContainer = styled.div`
         position: relative;
         width: 100%;
         color: ${(props) => props.theme.white};
         text-align: center;
         
         .background-video {
           width: 100%;
           position: absolute;
           top: 0;
           left: 0;
           bottom: 0;
           right: 0;
           z-index: -1;
           overflow: hidden;

           div {
             height: 100% !important;
           }

           video {
             object-fit:cover;
             height: 100% !important;
           }
           &:after {
             content: "";
             position: absolute;
             top: 0;
             right: 0;
             bottom: 0;
             left: 0;
             background-color: ${(props) => props.theme.black};
             opacity: 0.25;
           }
         }
       `;

export const StyledMainContainer = styled.div`
         .intro-icons {
           img {
             display: block;
             margin: 0 auto;
             max-width: 80px;
           }
           h3, p {
             text-align:center;
           }
         }
        @media screen and (max-width:768px) {
          .intro-icons:not(:first-child) {
            margin-top:40px;
          }
        }
       `;
export const StyledFullWidthContainer = styled.div`
         background-color: ${(props) => props.theme.lightGrey};
         position: relative;

         .full-width-img-third {
           position: absolute;
           right: 0;
           top: 0;
           bottom: 0;
           overflow: hidden;
           width: 33.3333%;
           text-align:right;

           img {
             width: 100%;
             height: 100%;
             object-fit: cover;
           }
         }
         @media screen and (max-width:768px) {
           .full-width-img-third {
             position: relative;
             width: 100%;
            
             img {
               max-width:100%;
               object-fit: cover;
               display:block;
               margin: 0 auto;
             }
           }
         }
       `;
export const StyledFullWidthContainerImg = styled.div`
         background-image: url("../images/flower-bg.jpg");
         background-position: center;
         background-size: cover;
         background-repeat: no-repeat;

        @media screen and (max-width:768px) {
          background-position: right;
        }
       `;
