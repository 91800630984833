import React from 'react';
import ReactPlayer from "react-player";

import Header from "./elements/Header";
import Footer from "./elements/Footer";
import homeVideoMp4 from "./images/leaves-blowing.mp4";


import {
  StyledHeaderContainer,
  StyledMainContainer,
} from "./styles/StyledHome";
import { Container, Col, Row } from "react-grid-system";

const Home = () => (
  <>
    <StyledHeaderContainer>
      <div className="background-video">
        <ReactPlayer
          url={homeVideoMp4}
          playing
          loop
          muted
          playsinline
          width="100%"
          height="auto"
        />
      </div>
      <Header page="home" />
      <Container>
        <Row>
          <Col md={12}>
            <div className="margin-top-15p margin-bottom-15p">
              <h1 className="heading">Oops!</h1>
              <h3 className="sub-heading margin-top-10 margin-bottom-60">
                Something went wrong
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledHeaderContainer>
    <StyledMainContainer>

      <Container>
        <Row>
          <Col md={10} offset={{ md: 1 }}>
            <div className="margin-top-80 align-center">
             The page you are looking for can't be found. Please try again or contact me if you have further issues.
            </div>
          </Col>
        </Row>
      </Container>
    
    </StyledMainContainer>
    <Footer />
  </>
);

export default Home;