import React from "react";
import { Router } from "@reach/router";

import Home from "./Home";
import Services from "./Services";
import Location from "./Location";
import FAQs from "./FAQs";
import Contact from "./Contact";
import Notfound from "./Notfound";

import { ThemeProvider } from "styled-components";

import { GlobalStyle } from "./styles/GlobalStyle";
import { theme } from "./styles/GlobalStyle";

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Home path="/" />
      <Notfound default />
      <Services path="/services" />
      <Location path="/location" />
      <Contact path="/contact" />
      <FAQs path="/faqs" />
    </Router>

    <GlobalStyle />
  </ThemeProvider>
);

export default App;
