import { createGlobalStyle } from "styled-components";

export const theme = {
         turqoise: "#52b7ad",
         turqoiseDark: "#3f8c85",
         teal: "#4d92ac",
         darkTeal: "#547689",
         black: "#262626",
         white: "#ffffff",
         lightGrey: "#f5f5f5",
         darkGrey: "#dfdfdf",
       };

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    font-size: 10px;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button, textarea {
    font-family: 'Nanum Gothic', Arial, sans-serif;
    color: ${(props) => props.theme.black};
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }

  //global styles

  //alignment
  .align-right {
    text-align:right;
  }
  .align-center {
    text-align:center;
  }
  .align-bottom {
    align-self: flex-end;
  }
  .align-top {
    align-self: flex-start;
  }
  .align-middle {
    align-self: center;
  }
  .centered {
    margin: 0 auto;
  }

  //Spacing
  .margin-top-10 {
    margin-top:10px;
  }
  .margin-top-20 {
    margin-top:20px;
  }
  .margin-top-30 {
    margin-top:30px;
  }
  .margin-top-40 {
    margin-top:40px;
  }
  .margin-bottom-60 {
    margin-bottom:60px;
  }
  .padding-bottom-20 {
    padding-bottom:20px;
  }

  .margin-top-80 {
    margin-top:80px;
  }
 
  .margin-top-15p {
    margin-top:15%;
  }
  .margin-bottom-15p {
    margin-bottom:15%;
  }

  .padding-lr-10 {
    padding-left:10px;
    padding-right:10px;
  }

  .padding-top-80 {
    padding-top:80px;
  }

  .padding-tb-80 {
    padding-top:80px;
    padding-bottom:80px;
  }

  @media screen and (max-width:768px) {
    .mb-margin-t-b-30 {
      margin-top:30px;
      margin-bottom:30px;
    }
  }

  //text
  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
  }
  @media screen and (max-width:768px) {
    h1 {
      font-size: 3.2rem;
      line-height: 3.7rem;
    }
  }
  h2 {
    font-size:3.5rem;
    line-height:2.5rem;
    font-weight:normal;
  }
  @media screen and (max-width:768px) {
    h2 {
      font-size: 2.9rem;
      line-height: 3.4rem;
    }
  }
  h3 {
    font-size:2.8rem;
    line-height:3.3rem;
    font-weight:normal;
  }
    @media screen and (max-width:768px) {
    h3 {
      font-size: 2.1rem;
      line-height: 2.6rem;
    }
  }
  h4 {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight:normal;
  }
  .heading {
    font-family: 'Lora', 'Times New Roman', serif;
  }
  .sub-heading {
    text-transform: uppercase;
    font-family: 'Nanum Gothic', Arial, sans-serif
  }
  p, div, input, textarea {
    line-height: 1.6em;
    font-size: 1.4rem;
    white-space: pre-wrap;
  }
  .sm-txt {
    font-size: 1rem;
  }
  a {
    color: ${(props) => props.theme.black};
  }

  .caps {
    text-transform:uppercase;
  }

  .services {
    background-image: url(../images/grass-bg.jpg);
  }
  .location {
    background-image: url(../images/the-practice-rooms.jpg);
  }
  .contact {
    background-image: url(../images/close-up-grass-bg.jpg);
  }
  .faqs {
    background-image: url(../images/beach-bg.jpg);
  }
  .light-grey-bg {
    background-color: ${(props) => props.theme.lightGrey};
  }
  .dark-grey-bg {
    background-color: ${(props) => props.theme.darkGrey};
  }
  .mapboxgl-ctrl, .mapboxgl-ctrl {
    display:none !important;
  }
  #marker {
     background-color: ${(props) => props.theme.teal};
  }
  .bullet-list {
    list-style: disc;
    margin-left:50px;
  }
  .errorText {
    color: ${(props) => props.theme.teal};
    margin-top:5px;
    display:block;
  }
  .vertical-align-center {
      align-self: center;
    }
  @media screen and (max-width:768px) {
    .hideCol {
      display:none;
    }
    .map {
      margin-top:30px;
    }
    .align-center-mb {
      align-self: center;
    }
  }
`;
