import React from "react";

import Header from "./elements/Header";
import Heading from "./elements/Heading";
import Subheading from "./elements/Subheading";
import Footer from "./elements/Footer";

import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";

import { StyledHeader } from "./styles/StyledHeader";
import { Container, Col, Row } from "react-grid-system";

const Location = () => {
  const Map = ReactMapboxGl({
    accessToken:
      "pk.eyJ1IjoiYWZrMSIsImEiOiJja2NnNjh6M2swb3p4MnlwYnI3NGQ1eW82In0.YbEsgNdVqzkHVqhmSujABw",
  });

  return (
    <>
      <StyledHeader className="location">
        <Header />
        <Container>
          <Row>
            <Col md={12}>
              <div className="margin-top-15p margin-bottom-15p">
                <h1 className="heading">Location</h1>
                <h3 className="sub-heading margin-top-10 margin-bottom-60">
                  The Practice Rooms
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </StyledHeader>
      <Container>
        <Row>
          <Col md={10} offset={{ md: 1 }}>
            <div className="margin-top-80 align-center">
              <p>
                I have two available locations to hold your sessions. We can
                work out the best place to meet during our initial session.
              </p>

              <p className="margin-top-20">
                Regrettably, The Practice Rooms are not wheelchair friendly due
                to the building being accessible via steps only, however if you
                would prefer face-to-face Counselling my second location on the
                outskirts of Exeter is wheelchair friendly.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="light-grey-bg margin-top-80 padding-tb-80">
        <Container>
          <Row>
            <Col md={4} className="align-middle">
              <Heading text="The Location" />
              <Subheading text="The Practice Rooms" />
              <p className="margin-top-20">
                15-16 Castle, {"\n"}
                Street Exeter,{"\n"}
                EX4 3PT
              </p>
            </Col>
            <Col md={8} className="map">
              <Map
                style="mapbox://styles/mapbox/streets-v8"
                containerStyle={{
                  height: "40vh",
                  width: "100%",
                }}
                center={[-3.52951, 50.725609]}
                zoom={[15]}
              >
                <Layer
                  type="symbol"
                  id="marker"
                  layout={{ "icon-image": "marker-15" }}
                  fillColor="#f03"
                  color={"red"}
                >
                  <Feature coordinates={[-3.52951, 50.725609]} />
                </Layer>
              </Map>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="dark-grey-bg padding-tb-80 align-center">
        <Container>
          <Row>
            <Col md={12}>
              <Heading text="Parking required?" />
              <Subheading text="Alternative location available" />
              <p className="margin-top-20">
                I also offer a limited amount of Counselling Sessions on the
                outskirts of Exeter for those with access to transport,{"\n"}
                there is free parking available at this location.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};
export default Location;
