import React, { useState} from "react";
import { Link } from "@reach/router";

import BrandLogo from '../images/logo-white.png';
import Navigation from "./Navigation";
import Burger from "./Burger";

import { StyledHeader, StyledLogo } from "../styles/StyledHeader";


import { Container, Col, Row } from "react-grid-system";

const Header = () => {

  const [open, setOpen] = useState(false);
 
  return (
    <StyledHeader>
      <Container>
        <Row>
          <Col md={3} xs={6}>
            <Link to="/">
              <StyledLogo
                src={BrandLogo}
                alt="Emily King Counselling and Psychotherapy"
              />
            </Link>
          </Col>
          <Col md={9} xs={6} className="vertical-align-center">
            <Burger open={open} setOpen={setOpen} />
            <Navigation open={open} setOpen={setOpen} type="header-navigation" />
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  );
};

export default Header;
