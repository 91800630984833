import React from "react";

import { StyledDefaultBtn } from "../styles/StyledDefaultBtn";

const DefaultBtn = ({ text, align, color }) => (
  <StyledDefaultBtn type="submit" align={align} color={color}>
    {text}
  </StyledDefaultBtn>
);

export default DefaultBtn;
