import styled from "styled-components";

export const StyledForm = styled.form`
    margin-top:60px;
    margin-bottom: 60px;

         label {
           display: block;
           margin-top: 20px;
         }
         input, textarea {
           display: block;
           background: ${(props) => props.theme.lightGrey};
           width:100%;
           padding: 12px 8px;
         }
       `;