import styled, { css } from 'styled-components';

export const StyledDefaultBtn = styled.button`
         background: transparent;
         padding: 10px 60px;
         width: auto;
         display: inline-block;
         text-transform: uppercase;
         letter-spacing: 0.1rem;
         cursor: pointer;
         display: block;
         margin: ${(props) => (props.align ? "0 auto" : "")};
         font-size: 1.4rem;
         border: 3px solid ${(props) => props.theme.black};
         color: ${(props) => props.theme.black};
         background: linear-gradient(to right, #262626, #262626);
         background-repeat: no-repeat;
         background-size: 0 100%;
         transition: background-size 0.5s 0s, color 0.5s 0s;

         ${(props) =>
           props.color &&
           css`
             background: linear-gradient(to right, #fff, #fff);
             background-repeat: no-repeat;
             background-size: 0 100%;
             transition: background-size 0.5s 0s, color 0.5s 0s;
             color: white;
             border: 3px solid ${(props) => props.theme.$color} !important;

             @media (hover: hover) {
               &:hover {
                 color: ${(props) => props.theme.black} !important;
                 background-size: 100% 100%;
                 border: 3px solid #fff !important;
               }
             }
           `}

        @media (hover: hover) {
             &:hover {
           color: ${(props) => props.theme.white};
           background-size: 100% 100%;
             }
         }

         @media screen and (max-width: 1000px) {
           padding: 10px 15px;
         }
       `;
