import styled from "styled-components";

export const StyledModal = styled.div`
z-index: 999999999;
      .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
      }         
      .modal-close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: white;
        z-index: 9999999999999;
      }
      .modal-close-button:hover {
        color: #52b7ad;
      }
      .modal-content {
        background-color: white;
        padding: 50px;
        width: 80%;
        max-width: 700px;
        text-align: center;
        position: relative;        
      }
     h2 {
       margin-bottom: 20px;
     }
     h4 {
       margin-bottom: 30px;
       line-height: 1.5em;
     }
     p{
       margin-bottom: 20px;
     }
`;