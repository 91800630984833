import styled from "styled-components";

export const StyledFooter = styled.div`
         background: ${(props) => props.theme.lightGrey};

         .footer-logos {
           img {
             max-width: 150px;
             display: block;
             margin-bottom: 20px;
           }
         }

         a {
             display:block;
           color: ${(props) => props.theme.black};
           text-decoration: none;
           position: relative;
           overflow: hidden;
           background: linear-gradient(
             to right,
             ${(props) => props.theme.turqoise},
             ${(props) => props.theme.turqoise} 50%,
             ${(props) => props.theme.black} 50%
           );
           background-clip: text;
           -webkit-background-clip: text;
           -webkit-text-fill-color: transparent;
           background-size: 200% 100%;
           background-position: 100%;
           transition: background-position 275ms ease;
           cursor: pointer;
           @media (hover: hover) {
             &:hover {
             background-position: 0 100%;
             }
           }
         }

         @media screen and (max-width: 768px) {
           text-align: center;

           img {
             margin: 20px auto 0;
           }
         }
       `;

export const StyledFooterDark = styled.div`
         background: ${(props) => props.theme.darkGrey};
         text-align: center;
         padding: 10px 0;
         div {
           a {
             color: ${(props) => props.theme.black};
             text-decoration: none;
             position: relative;
             overflow: hidden;
             background: linear-gradient(
               to right,
               ${(props) => props.theme.turqoise},
               ${(props) => props.theme.turqoise} 50%,
               ${(props) => props.theme.black} 50%
             );
             background-clip: text;
             -webkit-background-clip: text;
             -webkit-text-fill-color: transparent;
             background-size: 200% 100%;
             background-position: 100%;
             transition: background-position 275ms ease;
             cursor: pointer;
             @media (hover: hover) {
             &:hover {
               background-position: 0 100%;
             }
             }
           }
         }
       `;