import React from 'react';
import { bool } from "prop-types";
import { Link } from '@reach/router';

import { StyledNavigation } from "../styles/StyledNavigation";

const Navigation = ({ type, open }) => {
  return (
    <StyledNavigation open={open}>
      <div className={type}>
        <Link to="/">
          Home
        </Link>
        <Link to="/services">
          Counselling Services
        </Link>
        <Link to="/location">
          Location
        </Link>
        <Link to="/contact">
          Contact
        </Link>
        <Link to="/faqs">
          FAQs
        </Link>
      </div>
    </StyledNavigation>
  );
}
Navigation.propTypes = {
  open: bool.isRequired,
};

export default Navigation;
