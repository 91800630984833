import React from "react";
import { Link } from "@reach/router";

import ContactBanner from "./ContactBanner";
import BrandLogo from "../images/logo-fc.png";
import bacp from "../images/bacp.png";

import { Container, Col, Row } from "react-grid-system";
import {StyledFooter, StyledFooterDark} from "../styles/StyledFooter";

const Footer = () => (
  <>
    <ContactBanner />
    <StyledFooter>
      <Container>
        <Row style={{ paddingTop: 30 }}>
          <Col md={4} className="footer-logos" style={{ paddingBottom: 30 }}>
            <img
              src={BrandLogo}
              alt="Emily King, Counselling and Psychotherapy"
            />
            <img
              src={bacp}
              alt="British Association for Counselling and Psychotherapy"
            />
          </Col>
          <Col md={4} style={{ paddingBottom: 30 }}>
            <h3 className="heading">Get in touch</h3>
            <a
              href="mailto:info@emilykingcounselling.co.uk"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "block", marginBottom: 5, marginTop: 30 }}
            >
              info@emilykingcounselling.co.uk
            </a>
            <a href="tel:07985683715">07985683715</a>
          </Col>
          <Col md={4} style={{ paddingBottom: 30 }}>
            <h3 className="heading" style={{ marginBottom: 30 }}>
              Quick Links
            </h3>
            <Link to="/location">Location</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/faqs">FAQs</Link>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
    <StyledFooterDark>
      <div>
        © {new Date().getFullYear()} Emily King Counselling &amp; Psychotherapy{" "}
       
      </div>
      <div>
        Designed and built by{" "}
        <a
          href="https://32pixels.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          32Pixels
        </a>
      </div>
    </StyledFooterDark>
  </>
);

export default Footer;
