import React, { useEffect } from "react";
import {StyledModal} from "../styles/StyledModal";

function Modal({ closeModal }) {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    document.body.style.overflow = "hidden";

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      document.body.style.overflow = ""; 
    };
  }, [closeModal]);

  return (
    <StyledModal>
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <button className="modal-close-button" onClick={closeModal}>
          &times;
        </button>
        <div className="modal-content">
       
          <h2>Thank you for visiting my website</h2>
          <h4>Please note that I am not currently taking on any clients.</h4>
          <p>Emails and calls will not be being monitored at this time, so you may not receive a response if you try to make contact. </p>
          <p>Best Wishes,
            <br />
            Emily 
          </p>
        </div>
      </div>
    </StyledModal>
  );
}

export default Modal;