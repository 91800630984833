import React from "react";
import { Link } from "@reach/router";

import Header from "./elements/Header";
import Heading from "./elements/Heading";
import Subheading from "./elements/Subheading";
import Footer from "./elements/Footer";

import { StyledHeader } from "./styles/StyledHeader";
import { Container, Col, Row } from "react-grid-system";

const Location = () => (
  <>
    <StyledHeader className="services">
      <Header />
      <Container>
        <Row>
          <Col md={12}>
            <div className="margin-top-15p margin-bottom-15p">
              <h1 className="heading">Counselling Services</h1>
              <h3 className="sub-heading margin-top-10 margin-bottom-60">
                Understand the different services offered
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
    <Container>
      <Row>
        <Col md={10} offset={{ md: 1 }}>
          <div className="margin-top-80 align-center">
            <p>
              I can offer a range of short- and long-term Counselling services
              to meet your needs. Including face to face sessions, online
              counselling, and telephone appointments. My aim is to make
              Counselling accessible for everyone, please see my pricing below.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
    <div className="light-grey-bg margin-top-80 padding-tb-80">
      <Container>
        <Row>
          <Col md={12}>
            <Heading text="Initial session" />
            <Subheading text="£25 for 30 minutes" />
            <p className="margin-top-20">
              I offer an initial 30 minute session where we can discuss what it
              is that has brought you to Counselling, what you would like to
              achieve from Counselling and whether I would be the right fit for
              you as a Counsellor. In order to secure the session, I do ask for payment upfront, which is non-refundable.
            </p>
            <p className="margin-top-20">
              There is absolutely no obligation to book in any further sessions
              with me after the initial 30 minute session if you don’t feel that
              I am the right Counsellor for you.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
    <Container>
      <Row>
        <Col md={4}>
          <div className="dark-grey-bg margin-top-80 padding-tb-80 padding-lr-10">
            <h3 className="heading align-center">Counselling session</h3>
            <h4 className="align-center margin-top-20">£50 for 50 minutes</h4>
            <p className="margin-top-20">
              I offer both long term and short term face to face Counselling,
              you can decide what feels right for you.{"\n"}{"\n"}
            </p>
            
          </div>
        </Col>
        <Col md={4}>
          <div className="dark-grey-bg margin-top-80 padding-tb-80 padding-lr-10">
            <h3 className="heading align-center">Online Counselling</h3>
            <h4 className="align-center margin-top-20">£50 for 50 minutes</h4>
            <p className="margin-top-20">
              To make life a little easier for clients and to cut the commute
              time and costs, I am now offering Counselling sessions via
              various online platforms.{"\n"}
            </p>
            
          </div>
        </Col>
        <Col md={4}>
          <div className="dark-grey-bg margin-top-80 padding-tb-80 padding-lr-10">
            <h3 className="heading align-center">Telephone Counselling</h3>
            <h4 className="align-center margin-top-20">£50 for 50 minutes</h4>
            <p className="margin-top-20">
              Telephone Counselling is not everyone’s first preference, however
              if this is something you would like to consider then I am happy to
              discuss this with you.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
    <Footer />
  </>
);
export default Location;
