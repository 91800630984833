import React from "react";

import Header from "./elements/Header";
import Footer from "./elements/Footer";
import { Link } from "@reach/router";
import { StyledHeader } from "./styles/StyledHeader";
import { Container, Col, Row } from "react-grid-system";

const FAQs = () => (
  <>
    <StyledHeader className="faqs">
      <Header />
      <Container>
        <Row>
          <Col md={12}>
            <div className="margin-top-15p margin-bottom-15p">
              <h1 className="heading">FAQs</h1>
              <h3 className="sub-heading margin-top-10 margin-bottom-60">
                Got a question? Find common questions below
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
    <Container>
      <Row>
        <Col md={10} offset={{ md: 1 }}>
          <div className="margin-top-80 align-center">
            <p>
              Find a list of Frequently Asked Questions about the counselling
              service I provide below. If you have any additional questions,
              please don't hesitate to{" "}
              <Link to="/contact" style={{ color: "#52b7ad" }}>
                get in touch
              </Link>
              .
            </p>
          </div>
        </Col>
      </Row>
      <Row className="margin-top-80">
        <Col>
          <h4 className="padding-bottom-20">
            What issues can counselling help with?
          </h4>
          <p>
            Counselling can help with many difficult life problems, from coping
            with traumatic experiences and life events, to dealing with anxiety
            and depression or learning to manage harmful emotions and
            self-destructive behaviours. I am happy to discuss this in greater
            detail within our initial session.
          </p>
        </Col>
      </Row>
      <Row className="margin-top-80">
        <Col>
          <h4 className="padding-bottom-20">
            What will happen in my first session with you?
          </h4>
          <p>
            During your first session we will discuss what has brought you to
            Counselling, what you would like to gain from Counselling, and what
            your future goals may be.{" "}
          </p>
          <p>
            I will also go through an assessment and contract with you that
            helps me to find out a little bit more about you and gives you an
            opportunity to ask any questions that you may have, and for me to
            explain how the counselling process works.
          </p>
        </Col>
      </Row>
      <Row className="margin-top-80">
        <Col>
          <h4 className="padding-bottom-20">
            Will my sessions with you be recorded?
          </h4>
          <p>
            I will not record any of our sessions, the only time that a
            recording of a session may be required is if you have been asked to
            record one of your sessions with me by a professional, or if you
            would like to record a session for your own personal use.
          </p>
          <p>
            If you do wish to record a session, we would have to discuss this
            before the session started and we would need to contract how
            confidentiality would work.
          </p>
        </Col>
      </Row>
      <Row className="margin-top-80">
        <Col>
          <h4 className="padding-bottom-20">How often will I see you?</h4>
          <p>
            I prefer to let clients make the decision as to how often they would
            like to have sessions, the standard rate tends to be one session a
            week or one session every fortnight.{" "}
          </p>
          <p>If you would prefer to have less or more this can be discussed.</p>
        </Col>
      </Row>
      <Row className="margin-top-80">
        <Col>
          <h4 className="padding-bottom-20">How many sessions will I need?</h4>
          <p>
            I offer open ended Counselling sessions, this means that I don’t set
            a certain number of sessions at the start of your journey. You may
            wish to have Counselling for only a couple of months or even a
            couple of years, it is entirely up to you.
          </p>
        </Col>
      </Row>
      <Row className="margin-top-80">
        <Col>
          <h4 className="padding-bottom-20">
            What training and qualifications do you have?
          </h4>
          <ul className="bullet-list">
            <li>Certificate in Counselling (Level 3)</li>
            <li>
              Foundation Degree (FdA) in Integrative Counselling & Psychotherapy
              (Worcester University)
            </li>
            <li>
              Bachelor of Arts Degree (BA Hons) in Integrative Counselling &
              Psychotherapy (Worcester University){" "}
            </li>
            <li>
              Registered with the British Association of Counselling &
              Psychotherapy (BACP)
            </li>
            <li>DBS Registered</li>
          </ul>
          <p></p>
        </Col>
      </Row>
      <Row className="margin-top-40">
        <Col>
          <h4 className="padding-bottom-20">
            What is your cancellation policy?
          </h4>
          <p>
            I require a period of at least 48 hours notice if you intend to
            cancel your session, any missed sessions or alterations within the
            48 hour period will be charged at full price. Whilst sympathetic to
            emergencies, unfortunately being self employed means I cannot absorb
            the responsibility of last minute cancellations and no-shows.{" "}
          </p>
        </Col>
      </Row>
      <Row className="margin-top-30">
        <Col>
          <h4 className="padding-bottom-20">
            How do you maintain confidentiality?
          </h4>
          <p>
            You will be informed during the initial sessions how the information
            you provide will be used, stored and destroyed according to my
            insurance policy and recommendations from the BACP (British
            Association of Counselling and Psychotherapy). I am also registered
            with the ICO (The Information Commissioners Office) which ensures I
            am complying with GDPR rules set by the government. There are
            several reasons that a Counsellor may have to break confidentiality
            and share information with professionals, and these will be
            discussed in full detail during your initial session:
          </p>
          <ul className="bullet-list">
            <li>
              In order to protect a child, a vulnerable adult, the public from
              harm or abuse and yourself
            </li>
            <li>
              For the prevention and detection of a crime (Including: The
              Terrorism Act 2000, Drug Trafficking or Money Laundering, The Road
              Traffic Act 1991 &amp; 1998, The Serious Crime Act 2007 and The
              Female Genital Mutilation Act 2007)
            </li>
            <li>If I am legally compelled to do so by a court or law</li>
          </ul>
        </Col>
      </Row>
      <Row className="margin-top-40">
        <Col>
          <h4 className="padding-bottom-20">How do I pay?</h4>
          <p>
            Bank Transfer – 48 hours before the session please{"\n"}
          </p>
        </Col>
      </Row>
    </Container>
    <Footer />
  </>
);
export default FAQs;
