import React, { useState } from "react";

import Header from "./elements/Header";
import { Container, Col, Row } from "react-grid-system";

import { Link } from "@reach/router";
import {useForm} from "react-hook-form";
import axios from 'axios';

import BrandLogo from "./images/logo-fc.png";
import bacp from "./images/bacp.png";

import { StyledFooter, StyledFooterDark } from "./styles/StyledFooter";
import { StyledForm } from "./styles/StyledForm";
import { StyledHeader } from "./styles/StyledHeader";
import { StyledDefaultBtn } from "./styles/StyledDefaultBtn";

const Contact = () => {

  const [statusMessage, setStatusMessage] = useState('');
  const { register, handleSubmit, reset, errors } = useForm();
  const onSubmit = (data) => {
    
    axios({
      method: "POST",
      url: "https://emilykingcounselling.co.uk/index.php",
      data: data,
    })
    .then((response) => {
      console.log(data);
      if (response.data.status === "success") {
        setStatusMessage("Thank you! Your message has been successfully sent");
        reset();
      } else if (response.data.status === "fail") {
        setStatusMessage(
          "Something has gone wrong. Please Try again or email me directly: info@emilykingcounselling.co.uk");
      }
    });
  }

  return (
    <>
      <StyledHeader className="contact">
        <Header />
        <Container>
          <Row>
            <Col md={12}>
              <div className="margin-top-15p margin-bottom-15p">
                <h1 className="heading">Contact Me</h1>
                <h3 className="sub-heading margin-top-10 margin-bottom-60">
                  Get in touch to find out my availability
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </StyledHeader>
      <Container>
        <Row>
          <Col md={10} offset={{ md: 1 }}>
            <div className="margin-top-80 align-center">
              <p>
                I will aim to get back to you within 48 hours of your enquiry,
                unless I am on annual leave where you will receive an automated
                response advising you of the date that I return.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)} className="contactForm">
        <Container>
          <Row>
            <Col md={6}>
              <label>First name</label>
              <input
                name="firstName"
                ref={register({ required: true })}
                type="text"
              />
              {errors.firstName && (
                <span className="errorText">First name is required</span>
              )}
            </Col>
            <Col md={6}>
              <label>Last name</label>
              <input
                name="lastName"
                ref={register({ required: true })}
                type="text"
              />
              {errors.lastName && (
                <span className="errorText">Last name is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label>Email</label>
              <input
                name="email"
                ref={register({ required: true })}
                type="email"
              />
              {errors.email && (
                <span className="errorText">
                  Please add an email address so I can contact you
                </span>
              )}
            </Col>
            <Col md={6}>
              <label>Phone number</label>
              <input
                name="number"
                ref={register({ required: true })}
                type="number"
              />
              {errors.number && (
                <span className="errorText">
                  Please add a contact number address so I can call you
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <label>Time / day preference</label>
              <input name="timeDate" ref={register} type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <label>Enquiry</label>
              <textarea name="enquiry" ref={register({ required: true })} />
              {errors.enquiry && (
                <span className="errorText">
                  Please provide a little detail about the service you require
                </span>
              )}
            </Col>
          </Row>
          <Row className="margin-top-40">
            <Col md={12}>
              <StyledDefaultBtn type="submit" align="center">
                Send Enquiry
              </StyledDefaultBtn>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="align-center errorText">{statusMessage}</div>
            </Col>
          </Row>
        </Container>
      </StyledForm>
      <StyledFooter>
        <Container>
          <Row style={{ paddingTop: 30 }}>
            <Col md={4} className="footer-logos" style={{ paddingBottom: 30 }}>
              <img
                src={BrandLogo}
                alt="Emily King, Counselling and Psychotherapy"
              />
              <img
                src={bacp}
                alt="British Association for Counselling and Psychotherapy"
              />
            </Col>
            <Col md={4} style={{ paddingBottom: 30 }}>
              <h3 className="heading">Get in touch</h3>
              <a
                href="mailto:info@emilykingcounselling.co.uk"
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "block", marginBottom: 5, marginTop: 30 }}
              >
                info@emilykingcounselling.co.uk
              </a>
              <a href="tel:07985683715">07985683715</a>
            </Col>
            <Col md={4} style={{ paddingBottom: 30 }}>
              <h3 className="heading" style={{ marginBottom: 30 }}>
                Quick Links
              </h3>

              <Link to="/location">
                Location
              </Link>
              <Link to="/contact">
                Contact
              </Link>
              <Link to="/faqs">
                FAQs
              </Link>
            </Col>
          </Row>
        </Container>
      </StyledFooter>
      <StyledFooterDark>
       <div>
        © 2020 Emily King Counselling &amp; Psychotherapy{" "}
        {new Date().getFullYear()}
      </div>
      <div>
        Designed and built by{" "}
        <a
          href="https://32pixels.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          32Pixels
        </a>
      </div>
      </StyledFooterDark>
    </>
  );
};
export default Contact;
