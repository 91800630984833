import styled from 'styled-components';

export const StyledNavigation = styled.div`
         text-align: right;
         text-transform: uppercase;

         a {
           display: inline-block;
           color: ${(props) => props.theme.white};
           background: linear-gradient(
             to right,
             ${(props) => props.theme.turqoise},
             ${(props) => props.theme.turqoise} 50%,
             ${(props) => props.theme.white} 50%
           );
           overflow: hidden;
           background-clip: text;
           -webkit-background-clip: text;
           -webkit-text-fill-color: transparent;
           background-size: 200% 100%;
           background-position: 100%;
           transition: background-position 275ms ease;
           cursor: pointer;

          @media (hover: hover) {
             &:hover {
              background-position: 0 100%;
             }
           }
         }

         .header-navigation {
           padding-top: 20px;

           a {
             font-size: 1.8rem;
             padding: 20px 15px 0px;
           }
         }

         @media screen and (max-width: 768px) {
           .header-navigation {
             position: fixed;
             top: 0;
             right: 0px;
             height: 100%;
             background: ${(props) => props.theme.darkGrey};
             z-index: 2;
             width: 240px;
             transition: all 0.3s ease-in-out;
             transform: ${({ open }) =>
               open ? "translateX(0)" : "translateX(100%)"};

             a {
               display: block;
               color: ${(props) => props.theme.black};
               background: linear-gradient(
                 to right,
                 ${(props) => props.theme.turqoise},
                 ${(props) => props.theme.turqoise} 50%,
                 ${(props) => props.theme.black} 50%
               );
               background-clip: text;
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               background-size: 200% 100%;
               background-position: 100%;
               transition: background-position 275ms ease;
               cursor: pointer;

              @media (hover: hover) {
                &:hover {
                  background-position: 0 100%;
                }
               }
             }
           }
         }
       `;

export const StyledBurger = styled.button`
         display: none;

         @media screen and (max-width: 768px) {
           position: fixed;
           top: 5%;
           right: 35px;
           display: flex;
           flex-direction: column;
           justify-content: space-around;
           width: 2rem;
           height: 2rem;
           background: transparent;
           border: none;
           cursor: pointer;
           padding: 0;
           z-index: 10;
           padding: 0 20px;

           right: ${({ open }) => (open ? "280px" : "35px")};

           transition: right linear 0.3s;

           &:focus {
             outline: none;
           }

           @media (hover: hover) {
             &:hover {
               div {
                 background: ${(props) => props.theme.turqoise};
               }
             }
           }

           div {
             width: 2rem;
             height: 2px;
             background: ${(props) => props.theme.white};
             border-radius: 10px;
             transition: all 0.3s linear;
             position: relative;
             transform-origin: 1px;

             :first-child {
               transform: ${({ open }) =>
                 open ? "rotate(45deg)" : "rotate(0)"};
             }

             :nth-child(2) {
               opacity: ${({ open }) => (open ? "0" : "1")};
               transform: ${({ open }) =>
                 open ? "translateX(20px)" : "translateX(0)"};
             }

             :nth-child(3) {
               transform: ${({ open }) =>
                 open ? "rotate(-45deg)" : "rotate(0)"};
             }
           }
         }
       `;