import React from "react";
import { Link } from "@reach/router";

import DefaultBtn from "./DefaultButton";
import Heading from "./Heading";

import { Container, Col, Row } from "react-grid-system";

const ContactBannker = () => (
  <Container>
    <Row style={{ paddingTop: "5%", paddingBottom: "5%" }}>
      <Col md={8}>
        <Heading text="Book a session" />
        <h4 className="margin-top-20 caps">
          Get in touch for availability. 
        </h4>
      </Col>
      <Col md={4} className="mb-margin-t-b-30">
        <Link to="/contact">
          <DefaultBtn text="Get in touch" align="center" />
        </Link>
      </Col>
    </Row>
  </Container>
);

export default ContactBannker;
